import React from 'react';
import gambling from './assets/gambling.jpg';
import football from './assets/football.jpg';


export default function Gaming() {
    return (

        <section class="bg-white py-5 dark:bg-gray-900">
    <div class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
        <div class="font-light text-gray-500 sm:text-lg dark:text-gray-400">
            <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900">Accept payments for your online business</h2>
            <p class="mb-4">Accept Bitcoin and other cryptocurrencies, gain new customers, and avoid the cost of high fees and chargebacks.
<br />From setup to settlement, FairPay makes accepting these payments easy.</p>
        </div>
        <div class="grid grid-cols-2 gap-4 mt-8">
            <img class="w-full rounded-lg" src={gambling} alt="office content 1" />
            <img class="mt-4 w-full lg:mt-10 rounded-lg" src={football} alt="office content 2" />
        </div>
        <div className="flex pt-10 sm:pt-2 items-center sm:pl-6">
            <div class="flex-auto">
            <button class="bg-regal-blue hover:bg-low-blue text-md text-white font-semibold py-3 px-4  rounded-md shadow">
             Integrate now
            </button>
            </div>
            <div class="flex-auto">
            <button class="bg-white hover:bg-low-blue text-md border text-gray-900 font-semibold py-3 px-4  rounded-md shadow">
             Talk to Us
            </button>
            <span class="animate-ping absolute -ml-2 inline-flex h-2 w-2 rounded-full bg-regal-blue opacity-75"></span>
            </div>
            </div>
    </div>
</section>
    );
  };