import React from 'react';
import RampForm from './RampForm';

export default function Section() { 
    return (
      <div id="header-margin" className="relative flex -mt-56 sm:m-0 sm:h-max sm:px-24 clip-style flex-col bg-slate-950 py-5 sm:py-16 lg:pt-0 lg:flex-col lg:pb-0">
        <div class="sm:pt-10">
        <p className="sm:pr-5 text-center pt-5 lg:px-10 font-semibold text-2xl text-gray-50 sm:text-4xl ">
            Buy Crypto in One Click
        </p>
        <p className="sm:pr-5 text-center px-4 sm:pb-6 lg:px-10 text-slate-400 mb-2 text-lg text-gray-50 md:text-xl ">
            Instantly purchase your favorite digital assets hassle-free.
        </p>
        </div>
        <div class="flex justify-center mx-4 pt-4">
        <RampForm />
        </div>
      </div>
    );
  };