import React, {useState} from 'react';
import Email from './Email';
import Emailverify from './Emailverify';
import Biometrics from './Biometrics';
import Status from '../payment/Status';
import Payment from '../checkout/Payment';
import ReturnPayment from '../payment/Payment'




export default function Kyc({stepKycToForm}) {
  // Replace this with your app logic for determining dark mode
  const [emailOtp, setEmailOtp] = useState('');
  const [step, setstep] = useState(1);
  const [email, setEmail] = useState('');
  const nextStep = () => {
    setstep(step + 1);
  };
  const prevStep = () => {
    setstep(step - 1);
  };
  const stepEmailToForm = () => {
    stepKycToForm();
  };
  const sendEmailOtp = (otp, email) => {
    setEmailOtp(otp);
    setEmail(email);
  }

switch (step) {
  // case 1 to show stepOne form and passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
  case 1: 
    return (
    <div className="bg-white w-full flex rounded-2xl min-h-720">
     <Email nextStep={nextStep} stepEmailToForm={stepEmailToForm}  sendEmailOtp={ sendEmailOtp}/>
    </div>
    );
    case 2:
    return (
    <div className="bg-white w-full flex rounded-2xl min-h-720">
     <Emailverify nextStep={nextStep} prevStep={prevStep} emailOtp={emailOtp} email={email}/>
    </div>
    );
    case 3:
    return (
    <div className="bg-white w-full px-2 flex rounded-2xl min-h-720">
     <Biometrics nextStep={nextStep} email={email}/>
    </div>
    );
    case 4:
    return (
      <div className="bg-white w-full flex rounded-2xl min-h-720">
       <Payment nextStep={nextStep} prevStep={prevStep} email={email}/>
      </div>
    );
    case 5:
      return (
        <div className="bg-white w-full flex rounded-2xl min-h-720">
        <Status nextStep={nextStep} />
       </div>
      );
      case 6:
        return (
          <div className="bg-white w-full flex rounded-2xl min-h-720">
          <ReturnPayment  prevStep={prevStep} email={email}/>
         </div>
        );
  // default case to show nothing
  default:
    return (
      <div className="App">
      </div>
    );
}

}