import React from 'react';
import { ChatBubbleLeftRightIcon, ForwardIcon, ScaleIcon, ArrowsUpDownIcon } from '@heroicons/react/24/outline'

const features = [
  { 
    name: 'Lightning-fast transactions',
    description:
      'Increase conversions with the fastest crypto <> fiat deliveries on the market.',
    icon: ForwardIcon,
  },
  {
    name: 'Seamless flow',
    description:
      'Familiar e-commerce-like experience that convert beyond crypto natives.',
    icon: ArrowsUpDownIcon,
  },
  {
    name: 'Outstanding support',
    description:
      'Dedicated partnerships team and partner portal. 24/7 support for end-users.',
    icon: ChatBubbleLeftRightIcon,
  },
  {
    name: 'Built-in compliance',
    description:
      'We handle KYC, AML, and regulations on all transactions coming through our widget.',
    icon: ScaleIcon,
  },
]

export default function Features() {

  return (
    <div id="desiredSectionId" className="bg-slate-950 z-0 py-5 sm:py-32"> 
      <div className="mx-auto max-w-7xl sm:pb-20 px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-regal-blue">Seamless.Powerful</h2>
          <p className="sm:pr-5 text-center pt-4 lg:px-10 font-semibold text-2xl text-gray-50 md:text-xl ">
             The Crypto payment gateway for your business
          </p>
          <p className="sm:pr-5 text-center px-4 lg:px-10 text-slate-400 mb-2 text-lg  md:text-xl ">
          Join hundreds of global businesses and discover the power of fiat-crypto conversion on demand.
          </p>
        </div>
        <div  id="features" className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative  pl-16">
                <dt className="text-base font-bold leading-7 text-gray-50">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-regal-blue">
                    <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-base leading-7 text-slate-400">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}