import React from 'react';
import './App.css';
import './styles.css'; // Import your styles
import Homepage from './views/Homepage';
import Checkout from './views/Checkout';
import { Link, Route, Routes } from "react-router-dom";
import ThridParty from './checkout/ThirdParty';

function App() {
  return (
    <div className="App">
     <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/alt" element={<ThridParty />} />
      </Routes>
    </div>
  );
}

export default App;
