import React from 'react';
import Navbar from '../components/Navbar';
import Header from '../components/Header';
import Section from '../components/Section'; 
import Features from '../components/Features';
import Footer from '../components/Footer';
import Gaming from '../components/Gaming';
import Social from '../components/Social';
import Topbar from '../components/Topbar';
import Cookies from '../components/Cookies';

function Homepage() {

  return (
    <div>
      <Topbar />
      <Navbar />
      <Header /> 
      <Section />
      <Features />
      <Gaming />
      <Social  />
      <Footer />
      <Cookies />
    </div>
  );
}

export default Homepage;