import React, { useState, useEffect } from 'react';
import { ArrowSmallLeftIcon } from '@heroicons/react/20/solid';

 

const Emailverify = ({ nextStep, prevStep, emailOtp, email }) => {
    // Replace this with your app logic for determining dark mode
    const [inputOtp, setInputOtp] = useState('');
    const submitFormData = (e) => {
        if (inputOtp === emailOtp){
            e.preventDefault();
            nextStep();
        }else {
            alert('Entered OTP does not match. Please try again.');
        }
    }
    const goBack = (e) => {
        e.preventDefault();
        prevStep();
    }
    // Replace this with your app logic for determining dark mode

    return (
        <div class="mx-4 flex pt-2 flex-col">
            <div class="row">
                <div class="flex mb-2 mx-1">
                    <div class="flex-none cursor-pointer" onClick={goBack}><ArrowSmallLeftIcon className="h-12 text-black" /></div>
                    <div class="self-center grow font-medium text-lg whitespace-nowrap">Verify Your Email</div>
                </div>
            </div>
            <div class="flex h-full flex-col">
            <div class="flex justify-between mb-4 items-center mx-1">
        <div class="text-xs bg-gray-200 rounded-sm p-2"><p class="m-0">We've sent verification code to <b>{email}.</b><br></br>Check your spam folder in case you cannot find it in inbox</p></div>
      </div>
                <div class="mb-6 text-left">
                    <label for="otp" class=" mb-2 text-xs text-gray-500">Verification code</label>
                    <input type="text" onChange={(e) => setInputOtp(e.target.value)}  id="otp" class="bg-gray-200 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Enter the OTP here" required />
                    <div class="flex py-3">
    <input id="link-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
    <label for="link-checkbox" class="ml-2 text-xs text-gray-500">I agree with the Fairpay's <b>Terms of Use</b> and <b>Privacy Policy.</b></label>
</div>
                </div>
                <div class="row grow mx-1 pb-2">
                    <button class="mt-auto bg-regal-blue hover:bg-low-blue text-lg text-white  py-2 px-4  rounded shadow" onClick={submitFormData}>
                        Verify Email
                    </button>
                </div>
                <div class="text-center">
                <p class="text-xs text-10px text-gray-500">This site is protected by reCAPTCHA and the Google <span class='text-blue-600 underline'>Privacy Policy</span> and  <span class='text-blue-600 underline'>Terms of Service</span> apply.</p>
                </div>
            </div>
        </div>
    );

}
export default Emailverify;

