import react ,{useState, useEffect} from 'react';
import INR from '../paymentmethods/inr';
import USD from '../paymentmethods/usd';
import EUR from '../paymentmethods/euro';
import GBP from '../paymentmethods/gbp';



const StepsSell = ({ selectedCurrency, selectedCrypto, priceInSelectedCurrency }) => {
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
    const [fairPayFee, setFairPayFee] = useState(0);
    const [exchangeFee, setExchangeFee] = useState(0);
    const [totalFee, setTotalFee] = useState(0);
    const handlePaymentMethodChange = (method) => {
        setSelectedPaymentMethod(method);
      };
    useEffect(() => {
        localStorage.setItem('PaymentMethod', selectedPaymentMethod);
      }, [selectedPaymentMethod]);
    useEffect(() => {
        let calculatedFairPayFee = 0;
    
        if (selectedCurrency === 'USD') {
          calculatedFairPayFee = 1.99;
        } else if (selectedCurrency === 'INR') {
          calculatedFairPayFee = 150;
        } else if (selectedCurrency === 'EUR') {
          calculatedFairPayFee = 4.99;
        } else if (selectedCurrency === 'GBP') {
          calculatedFairPayFee = 2.99;
        }
    
        setFairPayFee(calculatedFairPayFee);
        localStorage.setItem('fairPayFee', calculatedFairPayFee);
      }, [selectedCurrency]);
    useEffect(() => {
        let calculatedExchangeFee = 0;
    
        if (selectedCurrency === 'USD') {
          calculatedExchangeFee = 0.99;
        } else if (selectedCurrency === 'INR') {
          calculatedExchangeFee = 81;
        } else if (selectedCurrency === 'EUR') {
          calculatedExchangeFee = 0.92;
        } else if (selectedCurrency === 'GBP') {
          calculatedExchangeFee = 0.80;
        }
    
        setExchangeFee(calculatedExchangeFee);
        localStorage.setItem('exchangeFee', calculatedExchangeFee);
      }, [selectedCurrency]);
      useEffect(() => {
        const calculatedTotalFee = (fairPayFee + exchangeFee);
        setTotalFee(calculatedTotalFee);
        localStorage.setItem('totalFee', calculatedTotalFee);
      }, [fairPayFee,exchangeFee ]);

    return (
        <div className="bg-white w-full rounded-2xl">
            <ol class="relative pt-3 m-0 text-gray-500 text-left border-l border-gray-300 dark:border-gray-300 dark:text-gray-400">
                <li class="mb-4">
                    <span class="absolute flex items-center justify-center w-8 h-8 bg-white rounded-full -left-4 ring-1 ring-gray-300 dark:ring-gray-300 dark:bg-green-900">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-regal-blue">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z" />
                        </svg>
                    </span>
                    <p class="text-md text-left text-gray-500">Withdrawal method</p>
                    <div class="flex flex-wrap gap-2">
                    {selectedCurrency === 'USD' && <USD onPaymentMethodChange={handlePaymentMethodChange} />}
                    {selectedCurrency === 'INR' && <INR onPaymentMethodChange={handlePaymentMethodChange}/>}
                    {selectedCurrency === 'EUR' && <EUR onPaymentMethodChange={handlePaymentMethodChange}/>}
                    {selectedCurrency === 'GBP' && <GBP onPaymentMethodChange={handlePaymentMethodChange}/>}
                    </div>
                </li>
                <li class="mb-2">
                    <span class="absolute flex items-center justify-center w-8 h-8 bg-white rounded-full -left-4 ring-1 ring-gray-300 dark:ring-gray-300 dark:bg-green-900">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-4 text-regal-blue">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15" />
                        </svg>
                    </span>
                    <p class="text-md pt-1 font-semibold text-left text-gray-500">{totalFee} {selectedCurrency} total fee</p>
                    <div class="flex">
                    <p class="leading-8">{fairPayFee} {selectedCurrency} Fairpay fee<br />
                       {exchangeFee} {selectedCurrency} Network / Exchange fee</p>
                    </div>
                </li>
                <li class="pb-2">
                    <span class="absolute flex items-center justify-center w-8 h-8 bg-white rounded-full -left-4 ring-1 ring-gray-300 dark:ring-gray-300 dark:bg-green-900">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-regal-blue">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 15.75V18m-7.5-6.75h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V13.5zm0 2.25h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V18zm2.498-6.75h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V13.5zm0 2.25h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V18zm2.504-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zm0 2.25h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V18zm2.498-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zM8.25 6h7.5v2.25h-7.5V6zM12 2.25c-1.892 0-3.758.11-5.593.322C5.307 2.7 4.5 3.65 4.5 4.757V19.5a2.25 2.25 0 002.25 2.25h10.5a2.25 2.25 0 002.25-2.25V4.757c0-1.108-.806-2.057-1.907-2.185A48.507 48.507 0 0012 2.25z" />
                        </svg>
                    </span>
                    <div class="flex pt-1">
                        <div><p class="text-md pr-1 font-bold text-left text-gray-500"> 1 {selectedCrypto} = {priceInSelectedCurrency} {selectedCurrency}</p></div>
                        <div><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                    </svg></div>
                    </div>
                </li>
            </ol>
        </div>
    );
}

export default StepsSell;
