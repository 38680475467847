import React, { useState } from 'react';
import hero from './assets/hero.png'


export default function Header() {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

    return (
      <div className="relative  flex h-screen sm:bg-none bg-cover -mt-10  flex-col py-5 sm:py-16 lg:pt-0 lg:flex-col lg:pb-0">
        <div className="inset-y-0 hidden sm:block top-0 right-0 z-0 w-full max-w-xl px-4 mx-auto md:px-0 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-7/12 lg:max-w-full lg:absolute xl:px-0">
          <svg
            className="absolute left-0 hidden h-full text-white transform -translate-x-1/2 lg:block"
            viewBox="0 0 100 100"
            fill="currentColor"
            preserveAspectRatio="none slice"
          >
            <path d="M50 0H100L50 100H0L50 0Z" />
          </svg>
          <img 
            className="object-cover w-full h-56 ml-20 sm:m-0 rounded shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:h-full"
            src={hero}
            alt=""
          />
        </div>
        <div className="relative  flex pt-24 sm:pt-0 flex-col items-start w-full max-w-xl px-4 mx-auto md:px-0 lg:px-8 lg:max-w-screen-xl">
          <div className="mb-16 lg:my-34 lg:max-w-lg lg:pr-5">
            <h3 className="mb-4 sm:pl-6 font-sans md:text-6xl text-left font-bold tracking-tight text-gray-900 text-4xl sm:leading-none">
            Accelerate your<br/>
            business with<br/>
            embedded crypto <br/>
            <span className='text-regal-blue'>payments</span>
            </h3>
            <p className="sm:pr-5 text-left sm:pl-6  mb-2 font-mono text-gray-800 md:text-xl ">
            Fairpay helps leading fintech companies receive, pay and store crypto. Unlock new revenue streams and delight your customer.
            </p>
            <div className="flex pt-10 sm:pt-4 items-center sm:pl-6">
            <button onClick={openModal} class="cursor-pointer bg-regal-blue hover:bg-low-blue text-md text-white font-semibold py-3 px-4 mr-4  rounded-md shadow" >
             Chat with an expert
            </button>
            {showModal && (
              <div  class="z-[1001] overflow-y-auto overflow-x-hidden fixed top-20 right-10 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
              <div class="relative p-4 w-full max-w-md max-h-full">
                
                  <div class="relative bg-white rounded-lg shadow">
           
                      <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                          <h3 class="text-lg font-semibold text-gray-900">
                              Speak to an expert
                          </h3>
                          <button type="button" onClick={closeModal} class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-toggle="crud-modal">
                              <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                              </svg>
                              <span class="sr-only">Close modal</span>
                          </button>
                      </div>
         
                      <form action="#" class="p-4 md:p-5">
                          <div class="grid gap-4 mb-4 grid-cols-2">
                              <div class="col-span-2">
                                  <label for="name" class="block mb-2 text-sm font-medium text-gray-900 ">Company name</label>
                                  <input type="text" name="name" id="name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="Type product name" required="" />
                              </div>
                              <div class="col-span-2 sm:col-span-1">
                                  <label for="category" class="block mb-2 text-sm font-medium text-gray-900 ">Select product</label>
                                  <select id="category" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5">
                                      <option selected="">Select product</option>
                                      <option value="TV">On-ramp</option>
                                      <option value="PC">Off-ramp</option>
                                      <option value="GA">KYC solution</option>
                                      <option value="PH">Wallet Services</option>
                                  </select>
                              </div>
                              <div class="col-span-2">
                                  <label for="description" class="block mb-2 text-sm font-medium text-gray-900">Anything else?</label>
                                  <textarea id="description" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500" placeholder="Tell us more about yout project, needs and timeline."></textarea>                    
                              </div>
                          </div>
                          <button type="submit" class="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center" onClick={closeModal}>
                             
                              Submit
                          </button>
                      </form>
                  </div>
              </div>
          </div> 
            )}
            </div>
          </div>
        </div>
      </div>
    );
  };