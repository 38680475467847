import React, {useState} from 'react';
import Form from '../checkout/Form';
import Wallet from '../checkout/Wallet';
import Kyc from '../kyc/Kyc';



export default function RampForm() {
  // Replace this with your app logic for determining dark mode
  const [step, setstep] = useState(1);
  const nextStep = () => {
    setstep(step + 1);
  };
  const prevStep = () => {
    setstep(step - 1);
  };
  const stepKycToForm = () => {
    setstep(2);
  }; 
   
switch (step) {
  // case 1 to show stepOne form and passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
  case 1:
    return (
      <div className="bg-white w-full  flex flex-col rounded-3xl min-h-720">
      <Form nextStep={nextStep} prevStep={prevStep} /> 
    </div>
    );
  // case 2 to show stepTwo form passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
  case 2:
    return (
      <div className="bg-white w-full rounded-2xl min-h-720">
      <Wallet nextStep={nextStep} prevStep={prevStep} />
     </div>
    );
    // Only formData is passed as prop to show the final value at form submit
  case 3:
    return (
      <div className="bg-white w-full rounded-2xl min-h-720">
       <Kyc stepKycToForm={stepKycToForm}/>
      </div>
    );
  // default case to show nothing
  default:
    return (
      <div className="App">
      </div>
    );
}

}