import React,{useState} from 'react'; 
import kyc from '../components/assets/kyc.svg';
import imageCompression from 'browser-image-compression';


const Biometrics = ({ nextStep, prevStep, email }) => {
const [images, setImages] = useState({ "adhaar-front": null, "adhaar-back": null, "selfie": null });

const postData = async () => {
    const url = 'https://fairpayy-5778bcaf99dd.herokuapp.com/api/user/biometrics';
    const data = {
      email: email,
      adhaarFront :images['adhaar-front'],
      adhaarBack : images['adhaar-back'],
      selfie : images['selfie'],
    };
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }
  
      const responseData = await response.json();
      console.log(responseData);
      // You can handle the responseData here as needed
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      // Handle error scenarios here
    }
  };


const handleImageChange = async (event, imageKey) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const options = {
          maxSizeMB: 0.1, // Set maximum size of the compressed image (in megabytes)
          maxWidthOrHeight: 1020, // Set maximum width or height of the compressed image
          useWebWorker: true, // Use web workers for faster compression (if available)
        };
  
        const compressedFile = await imageCompression(file, options);
        const reader = new FileReader();
  
        reader.onload = () => {
          const base64Image = reader.result;
          setImages({ ...images, [imageKey]: base64Image });
          localStorage.setItem(imageKey, base64Image);
        };
  
        reader.readAsDataURL(compressedFile);
      } catch (error) {
        console.error('Error compressing the image:', error);
      }
    }
  };
    // Replace this with your app logic for determining dark mode
const submitFormData = (e) => {
        e.preventDefault();
        const imagesNotEmpty = Object.values(images).some(image => image !== null);

        if (imagesNotEmpty) {
          nextStep();
          postData();
          // If images are null or empty, proceed to the next step
        } else {
          // Do nothing if images are not null or empty
          // Optionally, you can display a message or handle the case where images are not empty
          // For instance: alert("Please upload all required images.")
          alert("Please upload all required images.")
        }
    }
const goBack = (e) => {
        e.preventDefault();
        prevStep();
    }
    // Replace this with your app logic for determining dark mode

    return (
            <div class="mx-2 flex pt-2 flex-col">
                <div class="row">
                    <div class="flex mb-2 mx-1">
                        <div class="self-center mt-2 font-medium grow text-lg whitespace-nowrap">KYC Verification</div>
                    </div>
                </div>
                <div class="flex h-full flex-col">
                    <div class="flex justify-center">
                        <img src={kyc} class="h-18" />
                    </div>
                    <div class="flex justify-center">
                       <p class="text-xs">We kindly ask you to complete the ID verification process. As we're a financial service, we've to comply with <br /><span class="text-regal-blue font-semibold">KYC & AML requirements.</span></p>
                    </div>
                    <div class="flex flex-col mb-2">
                    <label for="adhaar-front" class={`flex flex-col text-gray bg-white focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded text-sm border ${images['adhaar-front'] ? 'relative' : ''}`}>
                        <div class="flex flex-col items-center justify-center">
                            <p class="text-sm m-0 p-2 text-gray">Adhaar Front</p>
                            {images['adhaar-front'] && (
            <div class="absolute top-0 right-0 p-2">
              {/* Checkmark icon */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 text-green-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
          )}
                        </div>
                        <input onChange={(e) => handleImageChange(e, 'adhaar-front')}id="adhaar-front" type="file" class="hidden" accept="image/*" capture="environment" />
                    </label>
                    </div>  
                    <div class="flex flex-col mb-2">
      <label
        for="adhaar-back"
        class={`flex flex-col text-gray bg-white focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded text-sm border ${images['adhaar-back'] ? 'relative' : ''}`}
      >
        <div class="flex flex-col items-center justify-center">
          <p class="text-sm m-0 p-2 text-gray">Adhaar Back</p>
          {images['adhaar-back'] && (
            <div class="absolute top-0 right-0 p-2">
              {/* Checkmark icon */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 text-green-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
          )}
        </div>
        <input
          onChange={(e) => handleImageChange(e, 'adhaar-back')}
          id="adhaar-back"
          type="file"
          class="hidden"
          accept="image/*"
          capture="environment"
        />
      </label>
    </div>
                    <div className="flex flex-col">
      <label
        htmlFor="dropzone-file"
        className="flex flex-col text-gray bg-white focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded text-sm border relative"
      >
        <div className="flex flex-col items-center justify-center">
          <p className="text-sm m-0 p-2 text-gray">Take Selfie</p>
          {images['selfie'] && ( // Conditionally render the checkmark when selfieImage exists
            <div className="absolute top-0 right-0 mt-2 mr-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 text-green-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
          )}
        </div>
        <input
          onChange={(e) => handleImageChange(e, 'selfie')}
          id="dropzone-file"
          type="file"
          className="hidden"
          accept="image/*"
          capture="user"
        />
      </label>
    </div> 
                    <div class=" flex flex-col">
                        <p id=" helper-text-explanation" class="mt-2 text-xs text-gray-500">If you don't complete your KYC within 1 hour, your order will be cancelled and any payment made will be refunded. </p>
                    </div>
                    <div class="row grow  items-end pb-2">
                        <div class="flex flex-col">
                        <div class="flex">
                        <button class="mt-auto w-full bg-regal-blue hover:bg-low-blue text-lg text-white  py-2 px-4  rounded shadow" onClick={submitFormData}>
                            Submit KYC
                        </button>
                        </div>
                        </div>
                    </div>
                    <div class=" flex flex-col">
                        <p id=" helper-text-explanation" class="mt-2 text-xs text-gray-500">We’ll never share your details.<br /> Read our <a href="#" class="text-blue-600 text-xs hover:underline">Privacy Policy</a>.</p>
                    </div>
                </div>
            </div>
    );

}
export default Biometrics;

