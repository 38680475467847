import React, {useState, useEffect} from 'react';
import { ArrowSmallLeftIcon, CloudArrowUpIcon, DocumentDuplicateIcon } from '@heroicons/react/20/solid';
import axios from 'axios';
import imageCompression from 'browser-image-compression';

const Payment = ({ nextStep, prevStep, email}) => { 
  const [locationData, setLocationData] = useState(null);
  const [minutes, setMinutes] = useState(60);
  const [seconds, setSeconds] = useState(0);
  const [utr, setUtr] = useState('');
  const [imageData, setImageData] = useState(localStorage.getItem('screenshot') || '');
  const [file, setFile] = useState(null);
  const [reference, setReference] = useState('');
  const [bankData, setBankData] = useState({
    acc : '',
    bank : '',
    branch : '',
    ifsc : '',
    mqr : '',
    name : '',
    type : '',
    upi : '',
    id : '',
  });
  const [walletAddress, setWalletAddress] = useState(''),//
  [selectedNetwork, setSelectedNetwork] = useState(''),//
  [youReceive, setYouReceive] = useState(''),//
  [totalFee, setTotalFee] = useState(''),//
  [fairPayFee, setFairPayFee] = useState(''),//
  [exchangeFee, setExchangeFee] = useState(''),//
  [youPay, setYouPay] = useState(''),//
  [selectedCurrency, setSelectedCurrency] = useState(''),//
  [PaymentMethod, setPaymentMethod] = useState(''),//
  [selectedCrypto, setSelectedCrypto] = useState(''),
  [BuySellflow, setBuySellflow] = useState(''); //

const postData = async () => {
    const userEmail = email;
    const url = `https://fairpayy-5778bcaf99dd.herokuapp.com/api/order/${userEmail}`;
    const data = {
      type : BuySellflow,
  amount: youPay,
  currency: selectedCurrency,
  crypto : selectedCrypto,
  cryptoAmount : youReceive,
  cryptoNetwork : selectedNetwork,
  paymentMethod : PaymentMethod,
  totalFee : totalFee,
  fairpayFee : fairPayFee,
  exchangeFee : exchangeFee,
  paymentScreenshot: imageData,
  utrNumber : utr,
  paymentReference : reference,
  wallet : walletAddress,
  receiverAccount : youReceive,
    };
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }
  
      const responseData = await response.json();
      console.log(responseData);
      // You can handle the responseData here as needed
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      // Handle error scenarios here
    }
  };
const fetchData = async () => {
    try {
      const bankId = '654ff43f63775b8ca4e612e4';
      const response = await axios.get(`https://fairpayy-5778bcaf99dd.herokuapp.com/api/bank/${bankId}`);
      const bank = response.data.data;
      setBankData({
        acc: bank.acc,
        bank: bank.bank,
        branch: bank.branch,
        ifsc: bank.ifsc,
        mqr: bank.mqr,
        name: bank.name,
        type: bank.type,
        upi: bank.upi,
        id: bank._id,
      });
    } catch (error) {
      console.error('Error fetching bank data:', error);
    }
  };
const referenceNumber = () => {
  const merchantId = "AAQQ";
  const userUniqueID = "XRVQ";
  const ref = merchantId + userUniqueID;
  setReference(ref);
  localStorage.setItem('referenceNumber',ref);
}
const getDataFromLocalStorage = () => {
  const dataMapping = {
    youReceive: setYouReceive,
    walletAddress : setWalletAddress,
    selectedNetwork : setSelectedNetwork,
    fairPayFee: setFairPayFee,
    exchangeFee: setExchangeFee,
    totalFee: setTotalFee,
    youPay: setYouPay,
    selectedCurrency: setSelectedCurrency,
    selectedCrypto : setSelectedCrypto,
    PaymentMethod : setPaymentMethod,
    BuySellflow : setBuySellflow,
  };

  for (const key in dataMapping) {
    const value = localStorage.getItem(key);
    if (value) {
      dataMapping[key](value);
    }
  }
};
const submitFormData = (e) => {
  if(utr === ''){
    alert("Please enter UTR number.")
  }
  else if(imageData === ''){
    alert("Please upload payment screenshot.")
  }else{
    e.preventDefault();
    postData();
    nextStep();
  }
}
  const goBack = (e) => {
    e.preventDefault();
    prevStep();
}
const handleInputChange = (e) => {
  const utr = e.target.value;
  setUtr(utr);
};
const handleImageChange = async (event) => {
  const file = event.target.files[0];
  setFile(file);

  if (file) {
    try {
      const options = {
        maxSizeMB: 0.1, // Set maximum size of the compressed image (in megabytes)
        maxWidthOrHeight: 1020, // Set maximum width or height of the compressed image
        useWebWorker: true, // Use web workers for faster compression (if available)
      };
      const compressedFile = await imageCompression(file, options);
    const reader = new FileReader();
    reader.onload = () => {
      const base64Image = reader.result;
      setImageData(base64Image);
      localStorage.setItem('screenshot', base64Image);
    };
    reader.readAsDataURL(compressedFile);
  } catch (error) {
    console.error('Error compressing the image:', error);
  }
  }
};
useEffect(() => {
  fetchData();
  referenceNumber();
}, []);
useEffect(() => {
  const timer = setInterval(() => {
    if (minutes === 0 && seconds === 0) {
      clearInterval(timer);
      // You can add code to handle timer expiration here
    } else {
      if (seconds === 0) {
        setMinutes(minutes - 1);
        setSeconds(59);
      } else {
        setSeconds(seconds - 1);
      }
    }
  }, 1000);

  return () => {
    clearInterval(timer);
  };
}, [minutes, seconds]);
useEffect(() => {
  getDataFromLocalStorage();
  localStorage.setItem('utr', utr);
}, [utr]);
useEffect(() => {
  const fetchLocationData = async () => {
    try {
      // Get the visitor's location data using the ip-api.com API
      const response = await axios.get('http://ip-api.com/json');

      if (response.data) {
        const { city, regionName, country, zip } = response.data;

        // Save the location data to local storage
        const location = {
          city,
          region: regionName,
          country,
          postalCode: zip,
        };

        // Update the state with the location data
        setLocationData(location);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  fetchLocationData();
}, []);


  return (
    <div class="justify-center mx-4 py-3 h-full">
    <div class="flex flex-col">
      <div class="flex mb-2 mx-1">
        <div class="self-center grow text-lg font-medium whitespace-nowrap">Complete Your Payment</div>
      </div>
      <div class="flex justify-between mb-2 items-center mx-1">
        <div class="text-xs bg-gray-200 rounded-sm p-2"><p class="m-0">Pay exactly <b>{youPay} {selectedCurrency}</b> to below bank account within <b>{minutes < 10 ? `0${minutes}` : minutes}:
        {seconds < 10 ? `0${seconds}` : seconds} minutes</b> from <b>your</b> account</p></div>
      </div>
      <div class="flex justify-between  items-center mx-1">
        <div class="text-sm font-semibold"><p>You pay</p></div>
        <div class="text-sm font-mono font-semibold"><p>{youPay} {selectedCurrency}</p></div>
      </div>
      <div class="flex justify-between  items-center mx-1">
        <div class="text-xs"><p>You're buying from <span class="border-dotted font-semibold border-b border-gray-300">{bankData.name}</span></p></div>
       
      </div>
      <button class="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500  focus:ring-4 focus:outline-none focus:ring-blue-300">
<span class="relative inline-flex items-center justify-center px-2 py-2.5 transition-all ease-in duration-75 bg-white  rounded-md group-hover:bg-opacity-0">
Powered by P2P technology
</span>
</button>
      <div class="">
    
                  <div class="text-sm px-2 pt-3 bg-zinc-100 shadow-md rounded-sm">
                      <div class="flex font-semibold justify-between  items-center mx-1">
                          <div class="text-xs"><p>BANK DETAILS</p></div>
                      </div>
                      <div class="">
                      <div class="text-xs mx-1 font-mono"><p class="text-left p-0 m-0 text-xs">Account Number</p></div>
                      <div class="flex justify-between  items-center mx-1">
                          <div class="text-md font-semibold font-mono"><p>{bankData.acc}</p></div>
                          <div class="text-xs"><p><DocumentDuplicateIcon className="h-4 text-gray-500" /></p></div>
                      </div>
                      </div>
                      <div class="">
                      <div class="text-xs mx-1 font-mono"><p class="text-left p-0 m-0 text-xs">IFSC </p></div>
                      <div class="flex justify-between  items-center mx-1">
                          <div class="text-md font-semibold font-mono"><p>{bankData.ifsc}</p></div>
                          <div class="text-xs"><p><DocumentDuplicateIcon className="h-4 text-gray-500" /></p></div>
                      </div>
                      </div>
                      <div class="">
                      <div class="text-xs mx-1 font-mono"><p class="text-left p-0 m-0 text-xs">Account Holder</p></div>
                      <div class="flex justify-between  items-center mx-1">
                          <div class="text-md font-semibold font-mono"><p>{bankData.name}</p></div>
                          <div class="text-xs"><p><DocumentDuplicateIcon className="h-4 text-gray-500" /></p></div>
                      </div>
                      </div>
                      <div class="">
                      <div class="text-xs mx-1 font-mono"><p class="text-left p-0 m-0 text-xs">Reference to Beneficiary</p></div>
                      <div class="flex justify-between  items-center mx-1">
                          <div class="text-md font-semibold font-mono"><p>{reference}</p></div>
                          <div class="text-xs"><p><DocumentDuplicateIcon className="h-4 text-gray-500" /></p></div>
                      </div>
                      </div>
                  </div>
                  <div class="text-sm px-2 mt-3 py-2 bg-zinc-200 shadow-md rounded-sm">
                   <div class="text-xs mx-1 font-mono"><p class="text-left font-semibold p-0 m-0 text-xs">UTR Number </p></div>
                   
                    <input
          type="text"
          className="row px-2 border rounded-sm mx-1 bg-white font-sans text-md text-gray-500"
          placeholder="Enter wallet address" 
          value={utr}
          onChange={handleInputChange}
          
        />
                  <div class="flex pt-3 justify-between  items-center mx-1 border-dotted border-b border-gray-300">
                  <div class="text-xs  mx-1 font-mono"><p class="text-left font-semibold p-0 m-0 text-xs">Payment Screenshot </p></div>
              <div>
                <label for="imageInput">
                  <div class="bg-regal-blue flex hover:bg-low-blue text-xs px-2 py-2 text-white  rounded shadow">
                    <CloudArrowUpIcon className="h-4 pr-2 text-white text-gray-500" /> Upload
                  </div>
                  <input onChange={handleImageChange}id="imageInput" type="file" class="hidden" accept=".pdf, .doc, .docx, .jpg, .jpeg,image/*" />
                </label>
                </div>
                  </div>
                </div>
      </div>
            <div class="row grow mx-1 pt-4">
            <button class="mt-auto bg-regal-blue hover:bg-low-blue text-lg text-white  py-2 px-4  rounded shadow" onClick={submitFormData}>
              I have Paid
            </button>
      </div>
    </div>
    </div>
  );
}
export default Payment;