import React, { useState, useEffect } from 'react';
import { ArrowSmallLeftIcon } from '@heroicons/react/20/solid';
import logo from '../components/assets/Fairpayy.png';
import axios from 'axios';
 


const Email = ({ nextStep, stepEmailToForm, sendEmailOtp }) => { 
    const [email, setEmail] = useState('');
    const [isEmailValid, setisEmailValid] = useState(false);
    const [emailOtp, setEmailOtp] = useState('');
    // Replace this with your app logic for determining dark mode
const checkIfEmailValid = ()=>{
        const emailString = email;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if(emailRegex.test(emailString)){
            setisEmailValid(true);
        }
        else{
            setisEmailValid(false);
        }
    }
const postData = async () => {
        const url = 'https://fairpayy-5778bcaf99dd.herokuapp.com/api/email-otp-verification/';
        const data = {
          email: email,
        };
      
        try {
          const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
          });
      
          if (!response.ok) {
            throw new Error('Network response was not ok.');
          }
      
          const responseData = await response.json();
          setEmailOtp(responseData.emailOtp);
          sendEmailOtp(responseData.emailOtp , email);
          // You can handle the responseData here as needed
        } catch (error) {
          console.error('There was a problem with the fetch operation:', error);
          // Handle error scenarios here
        }
      };
const submitFormData = (e) => {
        if (isEmailValid === true){
            e.preventDefault();
            nextStep();
            postData();
        }else {
            setisEmailValid(false);
        }
    }
const goBack = (e) => {
        e.preventDefault();
        stepEmailToForm();
    }
    useEffect(() => {
        localStorage.setItem('email', email);
        checkIfEmailValid();
      }, [email]);
    // Replace this with your app logic for determining dark mode

    return (
        <div class="mx-4 pt-2 flex flex-col">
            <div class="row">
                <div class="flex mb-2 mx-1">
                    <div class="flex-none cursor-pointer" onClick={goBack}><ArrowSmallLeftIcon className="h-12 text-black" /></div>
                    <div class="self-center font-medium grow text-lg whitespace-nowrap">Verify Your Email</div>
                </div>
            </div>
            <div class="flex h-full flex-col">
                <div class="flex justify-center">
                    <img src={logo} class="h-14" />
                </div>
                <div class="flex justify-center">
                    <p class="font-bold">Checkout with FairPay</p>
                </div>
                <div class="flex justify-center">
                    <p class="text-xs text-gray-500">The fastest and safest way to checkout on hundreds of crypto apps</p>
                </div>
                <div class="mb-6 text-left">
                    <label for="email" class=" mb-2 text-xs text-gray-500">Your email</label>
                    <input type="email" id="email" onChange={(e) => setEmail(e.target.value)} class="bg-gray-300 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="john.doe@example.com" required />
                    <p id=" helper-text-explanation" class="mt-2 text-xs text-gray-500">We’ll never share your details. Read our <a href="#" class="text-blue-600 text-xs hover:underline">Privacy Policy</a>.</p>
                </div>
                <div class="row grow mx-1 pb-2">
                    <button class="mt-auto bg-regal-blue hover:bg-low-blue text-lg text-white  py-2 px-4  rounded shadow" onClick={submitFormData}>
                        Continue
                    </button>
                </div>
                <div class="text-center">
                    <p class="text-xs text-10px text-gray-500">This site is protected by reCAPTCHA and the Google <span class='text-blue-600 underline'>Privacy Policy</span> and  <span class='text-blue-600 underline'>Terms of Service</span> apply.</p>
                </div>
            </div>
        </div>
    );

}
export default Email;

