import React from 'react';
import logo from './assets/Fairpayy.png';

export default function Navbar() {
    return (
        <nav className="border-b sm:py-1 border-regal-blue mx-auto z-10  flex  max-w-7xl items-center justify-center justify-between sm:px-20" aria-label="Global">
        <div className="flex lg:flex-1">
          <a href="#" className="-m-1.5 p-1.5">
            <span className="sr-only">Your Company</span>
            <img className="h-16 w-auto" src={logo} alt="" />
          </a>
        </div>
        </nav>
    );
  };