import React, { useState } from 'react';
import Select from 'react-select';

const CountryDropdown = ({ onCurrencyChange }) => {
  const [selectedDropdownCurrency, setSelectedDropdownCurrency] = useState({ value: 'EUR', label: 'EUR', flag: '🇪🇺' });

  const currencyOptions = [
    { value: 'USD', label: 'USD', flag: '🇺🇸' },
    { value: 'EUR', label: 'EUR', flag: '🇪🇺' },
    { value: 'GBP', label: 'GBP', flag: '🇬🇧' },
    { value: 'INR', label: 'INR', flag: '🇮🇳' },
    // Add more currencies with their flags as needed
  ];

  const handleChange = (selectedOption) => {
    setSelectedDropdownCurrency(selectedOption);
    onCurrencyChange(selectedOption.value);
  };

  return (
    <div>
      <Select
        value={selectedDropdownCurrency}
        onChange={handleChange}
        options={currencyOptions}
        isSearchable={false}
        styles={{
          control: (provided) => ({
            ...provided,
            border: 'none', // Remove the border
            background: 'none', // Remove the background color
          }),
          singleValue: (provided) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
          }),
        }}
        components={{
          IndicatorSeparator: () => null, // Remove the indicator separator
          DropdownIndicator: () => null, // Remove the dropdown indicator
        }}
        formatOptionLabel={({ label, flag }) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span role="img" aria-label={label} style={{ marginRight: '5px', fontSize:'22px'}}>{flag}</span>
            {label}
          </div>
        )}
      />
    </div>
  );
};

export default CountryDropdown;

