import React from 'react';
import Navbar from '../checkout/Navbar';
import Stepper from '../checkout/Stepper';


function Checkout() {
  return (
    <div>
      <Navbar />
      <Stepper />
    </div>
  );
}

export default Checkout;