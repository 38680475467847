import React, { useState } from 'react';


export default function Cookies() {
    const [showBanner, setShowBanner] = useState(true);
    const acceptCookies = () => {
        // Here you can set a cookie or use localStorage to remember the user's choice
        // For example, if using localStorage:
        localStorage.setItem('cookiesAccepted', 'true');
        setShowBanner(false);
      };
    
      const shouldShowBanner = () => {
        // Check if the user has already accepted cookies
        // For example, if using localStorage:
        return !localStorage.getItem('cookiesAccepted');
      };
    return (
   
        <section class="fixed max-w-md p-4 mx-4 bg-white border border-gray-200 dark:bg-gray-800 bottom-2 dark:border-gray-700 rounded-2xl" style={{ display: showBanner ? 'block' : 'none' }}>
        <h2 class="font-semibold text-gray-800 ">🍪 Cookie Notice</h2>
    
        <p class="mt-4 text-sm text-gray-600">We use cookies to ensure that we give you the best experience on our website. <a href="#" class="text-blue-500 hover:underline">Read cookies policies</a>. </p>
        
        <div class="flex items-center justify-between mt-4 gap-x-4 shrink-0">
            <button class="text-xs text-gray-800 underline transition-colors duration-300  hover:text-gray-600 focus:outline-none">
                Manage your preferences
            </button>
    
            <button class=" text-xs bg-gray-900 font-medium rounded-lg hover:bg-gray-700 text-white px-4 py-2.5 duration-300 transition-colors focus:outline-none" onClick={acceptCookies}>
                Accept
            </button>
        </div>
    </section>
    );
}