import react, {useState, useEffect} from 'react';
import { ArrowSmallLeftIcon } from '@heroicons/react/20/solid';
import btc from '../utils/assets/bitcoin.png';
import eth from '../utils/assets/ethereum.png';
import tether from '../utils/assets/tether.png';


const Wallet = ({ nextStep, prevStep}) => {
  const [walletAddress, setWalletAddress] = useState(''),
  [isValid, setIsValid] = useState(false),
  [selectedNetwork, setSelectedNetwork] = useState(''),
  [youReceive, setYouReceive] = useState(''),
  [priceInSelectedCurrency, setPriceInSelectedCurrency] = useState(''),
  [originalCost, setOriginalCost] = useState(''),
  [totalFee, setTotalFee] = useState(''),
  [fairPayFee, setFairPayFee] = useState(''),
  [exchangeFee, setExchangeFee] = useState(''),
  [youPay, setYouPay] = useState(''),
  [selectedCurrency, setSelectedCurrency] = useState(''),
  [selectedPaymentMethod, setSelectedPaymentMethod] = useState(''),
  [selectedCrypto, setSelectedCrypto] = useState(''); 
 
const getDataFromLocalStorage = () => {
    const dataMapping = {
      youReceive: setYouReceive,
      priceInSelectedCurrency: setPriceInSelectedCurrency,
      fairPayFee: setFairPayFee,
      exchangeFee: setExchangeFee,
      totalFee: setTotalFee,
      youPay: setYouPay,
      selectedCurrency: setSelectedCurrency,
      selectedCrypto : setSelectedCrypto,
      selectedPaymentMethod : setSelectedPaymentMethod,
    };
  
    for (const key in dataMapping) {
      const value = localStorage.getItem(key);
      if (value) {
        dataMapping[key](value);
      }
    }
  };
const validateWalletAddress = (address) => {
    // Ethereum wallet address validation regular expression
    const ethAddressRegex = /^0x[0-9a-fA-F]{40}$/;

    // Bitcoin wallet address validation regular expression (example: 1BvBMSEYstWetqTFn5Au4m4GFg7xJaNVN2)
    const btcAddressRegex = /^[13][a-km-zA-HJ-NP-Z1-9]{25,34}$/;

    // Tron wallet address validation regular expression (example: THHTa9kTNTwvJ3YiZyY3Ff6MT5cDL5ZeCm)
    const tronAddressRegex = /^T[1-9A-HJ-NP-Za-km-z]{33}$/;

    if (selectedNetwork === 'ETHEREUM' && ethAddressRegex.test(address)) {
      setIsValid(true);
    } else if (selectedNetwork === 'MAINNET' && btcAddressRegex.test(address)) {
      setIsValid(true);
    } else if (selectedNetwork === 'TRON' && tronAddressRegex.test(address)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };
const handleOptionChange = (option) => {
    setSelectedNetwork(option);
  };
  let options = ["MAINNET","ETHEREUM","ARBITRUM","OPTIMISM","POLYGONZKEVM","ZKSYNC","LINEA","BASE","BSC","TRON"];
  if (selectedCrypto === 'BTC') {
    options = ["MAINNET"];
  } else if (selectedCrypto === 'ETH') {
    options = ["ETHEREUM","ARBITRUM","OPTIMISM","POLYGONZKEVM","ZKSYNC","LINEA","BASE"];
  } else if (selectedCrypto === 'USDT') {
    options = ["ETHEREUM","ALGORAND","BSC","TRON"];
  }
  useEffect(() => {
    localStorage.setItem('selectedNetwork', selectedNetwork);
    getDataFromLocalStorage();
    const Cost = (youPay-totalFee);
    setOriginalCost(originalCost);
  }, [selectedNetwork]);
const submitFormData = (e) => {
  if (isValid === true ){
    if (walletAddress === '' || selectedNetwork === '' ){
    
    }
    else if( selectedCurrency === 'USD'){
      const baseUrl = `https://widget.wert.io/01GPNA4TQVKVJD3CNQHGRF2M47/widget/login?currency_amount=${youPay}&commodity=BTC%3ABitcoin&currency=USD&address=136d6fgZtdnznDXFohJtkNQWiFfEbzBx8F&click_id=8420f577-4834-4d73-abc6-76c6c87d117b`;
      window.location.href = baseUrl; // Replace with your custom URL
    }
    else if( selectedCurrency === 'EUR'){
      const baseUrl = `https://exchange.mercuryo.io/?currency=USDT&fiat_amount=${youPay}&fiat_currency=EUR&merchant_transaction_id=30c4804b-9673-cf51-9ad2-0caf78c2fc5f&network=TRON&signature=783a616fbc87b262843c5a54bc386d4d1894928371a77bea18cac6de8593669741c998b41054ed0d58f17fbfca5e047ecba66e098604e88d8fab5687961802c0&theme=trustwallet&utm_medium=referral&utm_source=TrustWallet&widget_id=d13d7a03-f965-4688-b35a-9d208819ff4b&address=TR5666NUsGU7gkvsnawW4eKdF8HkQZPCkh`;
      window.location.href = baseUrl;
      setTimeout(() => {
        window.location.reload(); // First reload
        setTimeout(() => {
          window.location.reload(); // Second reload
        }, 1000); // Adjust the delay as needed (milliseconds)
      }, 1000);
    }
    else if( selectedCurrency === 'GBP'){
      const baseUrl = `https://exchange.mercuryo.io/?currency=USDT&fiat_amount=${youPay}&fiat_currency=GBP&merchant_transaction_id=30c4804b-9673-cf51-9ad2-0caf78c2fc5f&network=TRON&signature=783a616fbc87b262843c5a54bc386d4d1894928371a77bea18cac6de8593669741c998b41054ed0d58f17fbfca5e047ecba66e098604e88d8fab5687961802c0&theme=trustwallet&utm_medium=referral&utm_source=TrustWallet&widget_id=d13d7a03-f965-4688-b35a-9d208819ff4b&address=TR5666NUsGU7gkvsnawW4eKdF8HkQZPCkh`;
      window.location.href = baseUrl;
      window.location.reload(); // First reload 

    }
    else {
      e.preventDefault();
      nextStep();
    }
  }else{
    alert("Enter a valid wallet address")
  }
}
const goBack = (e) => {
    e.preventDefault();
    prevStep();
}
const handleInputChange = (e) => {
  const address = e.target.value;
  setWalletAddress(address);
  validateWalletAddress(address);
};
useEffect(() => {
  // Ensure walletAddress is not empty before revalidating
  if (walletAddress.trim() !== '') {
    validateWalletAddress(walletAddress);
  }
  localStorage.setItem('walletAddress',walletAddress);
}, [selectedNetwork, walletAddress]);
useEffect(() => {
  const Cost = (youPay-totalFee);
  setOriginalCost(Cost);
  localStorage.setItem('originalCost', originalCost);
}, [originalCost]);
  return (
    <div class="mx-4 pt-2 justify-center flex h-full">
    <div class="flex flex-col">
      <div class="flex mb-2 mx-1">
        <div class="flex-none cursor-pointer" onClick={goBack}><ArrowSmallLeftIcon className="h-12 text-black" /></div>
        <div class="self-center font-medium grow text-lg whitespace-nowrap">Buy USDT To Your Wallet</div>
      </div>
      <div class="flex justify-between items-center mx-1">
        <div class=" text-xs">USDT WALLET ADDRESS</div>
        <div class="">
          
        <select class="bg-slate-300 px-2 py-1 font-semibold rounded-sm text-xs" value={selectedNetwork} onChange={(e) => handleOptionChange(e.target.value)}>
        <option value="">Select Network</option>
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
        </div>
      </div>
      <div class="row mt-1 border py-2 mx-1">
                <div class="col py-1">
                <input
          type="text"
          className="w-full text-xl font-sans outline-none bg-transparent"
          placeholder="Enter wallet address" 
          value={walletAddress}
          onChange={handleInputChange}
        />
                </div>
                <div class="col-2 flex items-center">
                    <div class="row">
                    <div class="col flex items-center">
                    {selectedCrypto === 'BTC' && (
                  <img className="pr-1 " src={btc} alt="Bitcoin" />
                   )}
                   {selectedCrypto === 'ETH' && (
                  <img className="pr-1 " src={eth} alt="Ethereum" />
                   )}
                   {selectedCrypto === 'USDT' && (
                  <img className="pr-1 " src={tether} alt="Tether" />
                   )}
                       
                    </div>
                    </div>
                </div>
      </div>
      <div class="row py-1 mx-1">
      {isValid ? (
            <p id="isValidAddress" className="text-xs text-green-700 p-0 text-left">
              Valid {selectedNetwork} wallet address
            </p>
          ) : (
            <p id="isValidAddress" className="text-xs text-red-700 p-0 text-left">
              Please enter a valid wallet address
            </p>
          )}
      </div>
      <div class="flex mt-2 justify-between py-2 items-center mx-1 border-dotted border-b border-gray-300">
        <div class="text-xs font-semibold">ORDER DETAILS</div>
      </div>
      <div class="flex justify-between py-2 items-center mx-1 border-dotted border-b border-gray-300">
        <div class="text-xs"><p class="inline-block align-middle"><b>{youReceive} {selectedCrypto}</b> @{priceInSelectedCurrency}</p></div>
        <div class="text-xs font-semibold"><p>{originalCost}</p></div>
      </div>
      <div class="flex justify-between py-2 items-center mx-1 border-dotted border-b border-gray-300">
        <div class="text-xs"><p>Our Fee</p></div>
        <div class="text-xs"><p>{fairPayFee}</p></div>
      </div>
      <div class="flex justify-between py-2 items-center mx-1 border-dotted border-b border-gray-300">
        <div class="text-xs"><p>Network/Exchange Fee</p></div>
        <div class="text-xs"><p>{exchangeFee}</p></div>
      </div>
      <div class="flex justify-between py-2 items-center mx-1">
        <div class="text-sm font-semibold"><p>Total</p></div>
        <div class="text-sm font-semibold"><p>{youPay} {selectedCurrency}</p></div>
      </div>
      <div class="row grow mx-1 pb-4">
            <button class="mt-auto bg-regal-blue hover:bg-low-blue text-lg text-white  py-2 px-4  rounded shadow" onClick={submitFormData}>
             Buy Now
            </button>
      </div>
      </div>
    </div>
  );
}
export default Wallet;