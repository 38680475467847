import React, {useState, useEffect} from 'react';
import timer from '../components/assets/timer.jpg';
import { ArrowSmallLeftIcon, CloudArrowUpIcon, DocumentDuplicateIcon } from '@heroicons/react/20/solid';



const Status = ({ nextStep }) => {
    const [walletAddress, setWalletAddress] = useState(''),
  [selectedNetwork, setSelectedNetwork] = useState(''),
  [youReceive, setYouReceive] = useState(''),
  [youPay, setYouPay] = useState(''),
  [selectedCurrency, setSelectedCurrency] = useState(''),
  [PaymentMethod, setPaymentMethod] = useState(''),
  [selectedCrypto, setSelectedCrypto] = useState(''),
  [BuySellflow, setBuySellflow] = useState(''); 
    // Replace this with your app logic for determining dark mode
    const handleClearLocalStorage = () => {
        localStorage.clear(); // Clear all contents in the local storage
        window.location.reload(); // Refresh the page
      };
    const submitFormData = (e) => {
        e.preventDefault();
        nextStep();
    }
    const getDataFromLocalStorage = () => {
        const dataMapping = {
          youReceive: setYouReceive,
          walletAddress : setWalletAddress,
          selectedNetwork : setSelectedNetwork,
          youPay: setYouPay,
          selectedCurrency: setSelectedCurrency,
          selectedCrypto : setSelectedCrypto,
          PaymentMethod : setPaymentMethod,
          BuySellflow : setBuySellflow,
        };
      
        for (const key in dataMapping) {
          const value = localStorage.getItem(key);
          if (value) {
            dataMapping[key](value);
          }
        }
      };
      useEffect(() => {
        getDataFromLocalStorage();
      }, []);
    // Replace this with your app logic for determining dark mode

    return (
        <div class="mx-4 flex flex-col">
            <div class="row">
                <div class="flex mb-2  pt-3">
                    <div class="self-center grow font-medium text-lg whitespace-nowrap">Order Status</div> 
                </div>
            </div>
            <div class="flex h-full flex-col">
                <div class="flex">
                    <div class=""><img src={timer} class="h-24"/></div>
                    <div class="col pt-3 px-4"><p class="text-left m-0">All done!</p><p class="text-xs text-left">We will send you a confirmation email once the order is processes.</p></div>
                </div>
                <div class="">
                    <div class="border rounded bg-gray-100 text-gray-600 text-sm px-3 py-1"><p class="m-0 text-left">Your will recieve your coins within 15 minutes</p></div>
                </div>
                <div class="flex flex-col mt-3">
                    <table class="border-collapse border rounded-lg border-slate-500 ...">
                        <tbody>
                            <tr class="">
                                <td class="border border-slate-700 text-left px-2">Order status</td>
                                <td class="border border-slate-700 text-gray-500 text-right px-2">Processing</td>
                            </tr>
                            <tr class="">
                                <td class="border border-slate-700 text-left px-2">Crypto</td>
                                <td class="border border-slate-700 text-regal-blue text-right px-2 break-all">{selectedCrypto}</td>
                            </tr>
                            <tr class="">
                                <td class="border border-slate-700 text-left px-2">Amount</td>
                                <td class="border border-slate-700 text-gray-500 text-right px-2">{youPay} {selectedCurrency}</td>
                            </tr>
                            <tr class="">
                                <td class="border border-slate-700 text-left px-2">Crypto</td>
                                <td class="border border-slate-700 text-gray-500 text-right px-2">{youReceive} {selectedCrypto}</td>
                            </tr>
                            <tr class="">
                                <td class="border border-slate-700 text-left px-2">Wallet address</td>
                                <td class="border border-slate-700 text-gray-500 text-right px-2 break-all"><p >{walletAddress}</p></td>
                            </tr>
                            <tr class="">
                                <td class="border border-slate-700 text-left px-2">Network</td>
                                <td class="border border-slate-700 text-gray-500 text-right px-2">{selectedNetwork}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row grow mx-1 items-end pb-2">
                        <div class="flex flex-col">
                        <div class="flex">
                        <button class="mt-auto w-full bg-regal-blue hover:bg-low-blue text-lg text-white  py-1 px-4  rounded shadow" onClick={submitFormData}>
                            Payment Details
                        </button>
                        </div>
                        <div class="flex">
                        <button class="mt-2 w-full bg-red-500 hover:bg-low-blue text-lg text-white  py-1 px-4  rounded shadow" onClick={handleClearLocalStorage}>
                            Cancel order
                        </button>
                        </div>
                        </div>
                    </div>
                <div class="text-center">
                    <p class="text-xs text-10px text-gray-500">This site is protected by reCAPTCHA and the Google <br />Privacy Policy and Terms of Service apply.</p>
                </div>
            </div>
        </div>
    );

}
export default Status;