import React, { useState } from 'react';
import visa from './assets/visa_master.png';
import apple from './assets/apple.svg';
import google from './assets/google.svg';



export default function AED({ onPaymentMethodChange }) {
    const [selectedMethod, setSelectedMethod] = useState(null);

    const handlePaymentMethodSelection = (method) => {
        setSelectedMethod(method);

        // Call the function passed from the parent component to update the selected payment method
        onPaymentMethodChange(method);
    };

    return (
        <>
            <div class="flex flex items-center">
                <div
                    id="visa"
                    className={
                        selectedMethod === 'Visa'
                            ? 'border-1 border-blue-500 flex items-center p-2 rounded-md transition-all'
                            : 'border flex items-center p-2 rounded-md transition-all'
                    }
                    onClick={() => handlePaymentMethodSelection('Visa')}
                >
                    <input
                        id="default-radio-Visa"
                        type="radio"
                        value=""
                        name="default-radio-2"
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label htmlFor="default-radio-Visa" class="flex items-center">
                        <img src={visa} class="w-10 pl-2" />
                        {selectedMethod === 'Visa' && (
                            <span class="pl-2 text-xs text-blue-900">Card payment</span>
                        )}
                    </label>
                </div>
            </div>
            <div class="flex flex items-center">
                <div
                    id="google"
                    className={
                        selectedMethod === 'Google'
                            ? 'border-1 border-blue-500 flex items-center p-2 rounded-md transition-all'
                            : 'border flex items-center p-2 rounded-md transition-all'
                    }
                    onClick={() => handlePaymentMethodSelection('Google')}
                >
                    <input
                        id="default-radio-Google"
                        type="radio"
                        value=""
                        name="default-radio-2"
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label htmlFor="default-radio-Google" class="flex items-center">
                        <img src={google} class="w-14 pl-2" />
                        {selectedMethod === 'Google' && (
                            <span class="pl-2 text-xs text-blue-900">Google pay</span>
                        )}
                    </label>
                </div>
            </div>
        </>
    );
}