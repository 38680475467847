import React, { useState } from 'react';
import Select from 'react-select';
import btc from './assets/bitcoin.png';
import eth from './assets/ethereum.png';
import usdt from './assets/tether.png';
import usdc from './assets/usdc.png';
import xrp from './assets/ripple.png';

const CryptoDropdown = ({onCryptoChange}) => {
  const [selectedCryptoCurrency, setSelectedCryptoCurrency] = useState({ value: 'ETH', label: 'ETH', icon: eth });

  const cryptoCurrencyOptions = [
    { value: 'BTC', label: 'BTC', icon: btc },
    { value: 'ETH', label: 'ETH', icon: eth },
    { value: 'USDT', label: 'USDT', icon: usdt },
    { value: 'USDC', label: 'USDC', icon: usdc },
    { value: 'XRP', label: 'XRP', icon: xrp },
    // Add more crypto currencies with their icon URLs as needed
  ];

  const handleChange = (selectedOption) => {
    setSelectedCryptoCurrency(selectedOption);
    onCryptoChange(selectedOption.value);
  };

  return (
    <div>
      <Select
        value={selectedCryptoCurrency}
        onChange={handleChange}
        options={cryptoCurrencyOptions}
        isSearchable={false}
        styles={{
          control: (provided) => ({
            ...provided,
            border: 'none', // Remove the border
            background: 'none', 
            // Remove the background color
          }),
          singleValue: (provided) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
          }),
        }}
        components={{
          IndicatorSeparator: () => null, // Remove the indicator separator
          DropdownIndicator: () => null, // Remove the dropdown indicator
        }}
        formatOptionLabel={({ label, icon, network }) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={icon} alt={label} style={{ marginRight: '10px', width: '24px' }} />
            {label}
          </div>
        )}
      />
    </div>
  );
};

export default CryptoDropdown;
