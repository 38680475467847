import React from 'react';
import logo from './assets/Fairpayy.png';
import { HeartIcon } from '@heroicons/react/24/solid'

export default function Footer() {
    return (
       <footer class="p-4 bg-white md:p-8 lg:p-10 dark:bg-gray-800">
  <div class="mx-auto max-w-screen-xl text-center">
      <a href="#" class="flex justify-center items-center text-2xl font-semibold text-gray-900 dark:text-white">
      <img className="h-12 w-auto" src={logo} alt="" />  
      </a>
      <p class="flex justify-center">Made with <HeartIcon className="h-6 w-6 mx-2 text-red-500" />in USA 🇺🇸 </p>
      {/* <p class="text-sm font-medium ">Onboard millions of users to Web3</p> */}
      <ul class="flex mt-2 flex-wrap text-sm justify-center items-center mb-2 text-gray-500">
          <li>
              <a href="#" class="mr-4 no-underline  text-gray-500 md:mr-6 ">About</a>
          </li>
          <li>
              <a href="#" class="mr-4 no-underline text-gray-500 md:mr-6">Privacy</a>
          </li>
          <li>
              <a href="#" class="mr-4 no-underline text-gray-500 md:mr-6 ">Licensing</a>
          </li>
      </ul>
    {/*  <p class="text-sm font-medium ">Fairpay is a global web3 infrastructure services provider with registered entities in the USA, Canada, the UK, Portugal, and France.</p> */}
      <span class="text-sm text-gray-500 sm:text-center">© 2021-2023 <a href="#" class="no-underline text-gray-800 font-medium">FairPay™</a>. All Rights Reserved.</span>
  </div>
</footer>
    );
  };