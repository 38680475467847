import React, { useState, useEffect } from 'react';
import axios from 'axios';



export default function ThridParty() {
    const [websiteContent, setWebsiteContent] = useState('');

        // Make an HTTP request to the website's URL
        axios.get('https://www.wikipedia.org/',{
            headers: {
              'User-Agent': 'Your User Agent String',
            }
          }) // Replace with the URL of the website you want to display
          .then((response) => {
            setWebsiteContent(response.data);
          })
          .catch((error) => {
            console.error('Error fetching website content', error);
          });

    return (
    <div>
      <h1>External Website</h1>
      <div dangerouslySetInnerHTML={{ __html: websiteContent }} />
    </div>
    );
};