import React, {useState, useEffect} from 'react'; 
import Steps from '../components/Steps';
import StepsSell from '../components/StepsSell';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Tabs } from '@mui/base/Tabs';
import { TabsList as BaseTabsList } from '@mui/base/TabsList';
import { TabPanel as BaseTabPanel } from '@mui/base/TabPanel';
import { Tab as BaseTab } from '@mui/base/Tab';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import CountryDropdown from '../utils/CountryDropdown';
import CryptoDropdown from '../utils/CryptoDropdown';
import axios from 'axios';

 
const Form = ({ nextStep }) => {
  const [prices, setPrices] = useState({
    USDT: { INR: "", EUR: "", GBP: "" },
    BTC: { INR: "", EUR: "", GBP: "" },
    ETH: { INR: "", EUR: "", GBP: "" },
    XRP: { INR: "", EUR: "", GBP: "" },
  });
  const [locationData, setLocationData] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState('EUR');
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
  const [selectedCrypto, setSelectedCrypto] = useState('ETH');
  const [priceInSelectedCurrency, setPriceInSelectedCurrency] = useState('');
  const [youReceive, setYouReceive] = useState('0');
  const [value, setValue] = useState(0);
  const [youPay, setYouPay] = useState('');
  const [totalFee, setTotalFee] = useState(0);
  const [alertMessage, setAlertMessage] = useState('');

  const formValidation = () => {
    const inputValue = youPay;
    if (selectedPaymentMethod === 'Visa' && inputValue < 100) {
      setAlertMessage("Minimum amount should be above 100");
    } else if (selectedPaymentMethod === 'Visa' && inputValue > 500) {
      setAlertMessage("Maximum amount for card payment should be below 500");
    } else if (selectedPaymentMethod === 'Apple' && inputValue < 100) {
      setAlertMessage("Minimum amount should be above 100");
    }else if (selectedPaymentMethod === 'Apple' && inputValue > 2000) {
      setAlertMessage("Maximum amount for apple pay should be below 2000");
    }else if (selectedPaymentMethod === 'Sepa' && inputValue < 100) {
      setAlertMessage("Minimum amount should be above 100");
    }else if (selectedPaymentMethod === 'Sepa' && inputValue > 10000) {
      setAlertMessage("Maximum amount for sepa transfer should be below 10000");
    }else if (selectedPaymentMethod === 'Google' && inputValue < 100) {
      setAlertMessage("Minimum amount should be above 100");
    }else if (selectedPaymentMethod === 'Google' && inputValue > 250) {
      setAlertMessage("Maximum amount for google pay should be below 250");
    }else if (selectedPaymentMethod === 'Imps' && inputValue < 10000) {
      setAlertMessage("Minimum amount should be above 10000");
    }else if (selectedPaymentMethod === 'Imps' && inputValue > 200000) {
      setAlertMessage("Maximum amount for IMPS should be below 200000");
    }else if (selectedPaymentMethod === 'Upi' && inputValue < 10000) {
      setAlertMessage("Minimum amount should be above 10000");
    }else if (selectedPaymentMethod === 'Upi' && inputValue > 50000) {
      setAlertMessage("Maximum amount for UPI payment should be below 50000");
    } else {
      setAlertMessage(''); // Clear the alert message if conditions are not met
    }
  }
  const handleTotalFeeChange = (totalFee) => {
    setTotalFee(totalFee);
  };
  const updateSelectedPaymentMethod = (method) => {
    setSelectedPaymentMethod(method);
  };
  const submitFormData = (e) => {
    if (youPay !== '' && selectedPaymentMethod !== '') {
      if (
        (selectedPaymentMethod === 'Visa' && youPay >= 100 && youPay <= 500) ||
        (selectedPaymentMethod === 'Apple' && youPay >= 100 && youPay <= 2000) ||
        (selectedPaymentMethod === 'Sepa' && youPay >= 100 && youPay <= 10000) ||
        (selectedPaymentMethod === 'CashApp' && youPay >= 100 && youPay <= 500) ||
        (selectedPaymentMethod === 'Google' && youPay >= 100 && youPay <= 500) ||
        (selectedPaymentMethod === 'Wire' && youPay >= 100 && youPay <= 10000) ||
        (selectedPaymentMethod === 'Upi' && youPay >= 10000 && youPay <= 50000) ||
        (selectedPaymentMethod === 'Imps' && youPay >= 10000 && youPay <= 200000) ||
        (selectedPaymentMethod === 'Rupay' && youPay >= 10000 && youPay <= 100000)
      ) {
        nextStep();
      }
    }else if(youPay !== ''){
      alert('Please enter amount')
    }
    else if(selectedPaymentMethod !== ''){
      alert('Please select payment method')
    }
  };
  const handleCurrencyChange = (newCurrency) => {
    setSelectedCurrency(newCurrency);
    setYouPay('')
  };
  const handleCryptoChange = (newCrypto) => {
    setSelectedCrypto(newCrypto);
    setYouPay('')
  };
  const calculateYouReceive = () => {
    if (!youPay || !prices[selectedCrypto]) {
      setYouReceive('0'); // Handle the case when youPay is empty or prices[selectedCrypto] is unavailable
      return;
    }
  
    const priceInSelectedCurrency = prices[selectedCrypto][selectedCurrency];
    if (priceInSelectedCurrency !== null) {
      const youPayValue = parseFloat(youPay)- totalFee;
      const decimalPoints = selectedCrypto === 'BTC' || selectedCrypto === 'ETH' ? 8 : 2;
      let youReceiveValue;
  
      if (value === 0) {
        youReceiveValue = youPayValue / priceInSelectedCurrency;
      } else if (value === 1) {
        youReceiveValue = youPayValue * priceInSelectedCurrency;
      }
  
      setPriceInSelectedCurrency(priceInSelectedCurrency);
      if (isNaN(youReceiveValue)) {
        setYouReceive('0');
      } else {
        if (value === 1) {
          setYouReceive(youReceiveValue.toFixed(2)); // Rounded to 2 decimal places for sell
          localStorage.setItem('youReceive', youReceiveValue.toFixed(2));
        } else {
          setYouReceive(youReceiveValue.toFixed(decimalPoints));
          localStorage.setItem('youReceive', youReceiveValue.toFixed(decimalPoints));
        }
      }
    } else {
      setYouReceive('0'); // Handle the case when priceInSelectedCurrency is unavailable
    }
  };
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    setSelectedCurrency('EUR');
    setSelectedCrypto('ETH');
    setYouReceive('0')
    setYouPay('')
  };
  const handleYouPayChange = (e) => {
    const inputValue = e.target.value;
    // Use regular expression to allow only numbers
    const numericValue = inputValue.replace(/[^0-9]/g, '');
    // Update the input value
    setYouPay(numericValue)  
  };
  useEffect(() =>{
    const priceEth = prices.ETH.EUR;
    setPriceInSelectedCurrency(priceEth)
  },[prices])
  useEffect(() => {
    const fetchPrices = async () => {
      try {
        // Fetch cryptocurrency prices from CoinGecko API
        const response = await axios.get('https://api.coingecko.com/api/v3/simple/price', {
          params: {
            ids: 'tether,bitcoin,ethereum,ripple',
            vs_currencies: 'inr,eur,gbp,usd,aed',
          },
        });

        if (response.data) {
          const data = response.data;
          setPrices({
            USDT: { INR: data.tether.inr, EUR: data.tether.eur, GBP: data.tether.gbp, USD: data.tether.usd,  AED: data.tether.aed },
            BTC: { INR: data.bitcoin.inr, EUR: data.bitcoin.eur, GBP: data.bitcoin.gbp ,USD: data.bitcoin.usd, AED: data.bitcoin.aed},
            ETH: { INR: data.ethereum.inr, EUR: data.ethereum.eur, GBP: data.ethereum.gbp, USD: data.ethereum.usd, AED: data.ethereum.aed },
            XRP: { INR: data.ripple.inr, EUR: data.ripple.eur, GBP: data.ripple.gbp, USD: data.ripple.usd, AED: data.ripple.aed },
          });
          localStorage.setItem('prices', JSON.stringify(data));
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchPrices();
  }, []);
  useEffect(() => {
    const savedYouPay = localStorage.getItem('youPay');
    if (savedYouPay) {
      setYouPay(savedYouPay);
    }
    const fetchLocationData = async () => {
      try {
        // Get the visitor's location data using the ip-api.com API
        const response = await axios.get('http://ip-api.com/json');

        if (response.data) {
          const { city, regionName, country, zip } = response.data;

          // Save the location data to local storage
          const location = {
            city,
            region: regionName,
            country,
            postalCode: zip,
          };

          localStorage.setItem('locationData', JSON.stringify(location));

          // Update the state with the location data
          setLocationData(location);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchLocationData();
  }, []);
  useEffect(() => {
    calculateYouReceive();


    setPriceInSelectedCurrency(prices[selectedCrypto][selectedCurrency])
    localStorage.setItem('BuySellflow', value);
    localStorage.setItem('selectedCurrency', selectedCurrency);
    localStorage.setItem('selectedCrypto', selectedCrypto);
    localStorage.setItem('youPay', youPay);
    localStorage.setItem('priceInSelectedCurrency', priceInSelectedCurrency);
    localStorage.setItem('youReceive', youReceive);

  }, [value, selectedCurrency, selectedCrypto, youPay, priceInSelectedCurrency]);
  useEffect(() => {
    formValidation();
  }, [youPay, selectedPaymentMethod]);
  return (
    <div class="flex grow flex-col">
      <Tabs value={value} onChange={handleTabChange}>
        <TabsList>
          <Tab value={0}>Buy</Tab>
          <Tab value={1} disabled={true}>Sell</Tab>
        </TabsList>
        <TabPanel value={0}>
          <div class="row border mx-2">
            <div class="col-8 py-1">
              <div class="row pl-4 font-sans text-xs text-gray-500">
                You pay
              </div> 
              <div class="row pl-4 text-xl font-sans">
                <input
                  className="w-full text-xl font-sans outline-none bg-transparent"
                  placeholder="10000"
                  type="number"
                  value={youPay}
                  onInput={handleYouPayChange}
                  style={{ '-moz-appearance': 'textfield' }} // Hide increment/decrement arrows in Firefox
                  pattern="\d*" // Hide increment/decrement arrows in Chrome and Safari

                />
              </div>
            </div>
            <div class="col-4 flex p-0 items-center border-l bg-low-gray">
              <div class="grow">
                <CountryDropdown onCurrencyChange={handleCurrencyChange} />
              </div>
            </div>
            <div class="bg-red-100 text-red-700">{alertMessage}</div>
          </div>
          <div class="ms-5">
                <Steps selectedCurrency={selectedCurrency} selectedCrypto={selectedCrypto} priceInSelectedCurrency={priceInSelectedCurrency} updateSelectedPaymentMethod={updateSelectedPaymentMethod} getTotalFee={handleTotalFeeChange}  />
            </div>
            <div class="row border mx-2">
                <div class="col-8 py-1">
                    <div class="row pl-4 font-sans text-xs text-gray-500">
                        You recieve (estimate)
                    </div>
                    <div id="youReceive"class="row pl-4 text-xl font-sans">
                       {youReceive}
                    </div>
                </div>
                <div class="col flex p-0 items-center border-l bg-low-gray">
                    <div class="grow">
                    <CryptoDropdown onCryptoChange={handleCryptoChange} />
                    </div>
                </div>
            </div>
        </TabPanel>
        <TabPanel value={1}>
        <div class="row border mx-2">
                <div class="col-8 py-1">
                    <div class="row pl-4 font-sans text-xs text-gray-500">
                        You pay
                    </div>
                    <div class="row pl-4 text-xl font-sans">
                    <input
          type="text"
          className="w-full text-xl font-sans outline-none bg-transparent"
          placeholder="10000"
          value={youPay}
          onChange={(e) => setYouPay(e.target.value)}
        />
                    </div>
                </div>
            <div class="col flex p-0 items-center border-l bg-low-gray">
              <div class="grow">
                 <CryptoDropdown onCryptoChange={handleCryptoChange} />
                
              </div>
            </div>
            </div>
            <div class="ms-5">
                <StepsSell selectedCurrency={selectedCurrency} selectedCrypto={selectedCrypto} priceInSelectedCurrency={priceInSelectedCurrency}  />
            </div>
            <div class="row border mx-2">
                <div class="col-8 py-1">
                    <div class="row pl-4 font-sans text-xs text-gray-500">
                        You recieve (estimate)
                    </div>
                    <div id="youReceive"class="row pl-4 text-xl font-sans">
                       {youReceive}
                    </div>
                </div>
                <div class="col flex p-0 items-center border-l bg-low-gray">
                    <div class="grow">
                    <CountryDropdown onCurrencyChange={handleCurrencyChange}/>
                    </div>
                </div>
            </div>
        </TabPanel>
      </Tabs>
      <div class="row grow items-end mx-4 pb-4">
            <button class="bg-regal-blue hover:bg-low-blue text-lg text-white  py-2 px-4 rounded shadow focus:ring-4 transform active:scale-y-75 transition-transform" onClick={submitFormData}>
             Buy Now
      </button>
      </div>
    </div>
  );
}
export default Form;


const resolveSlotProps = (fn, args) => (typeof fn === 'function' ? fn(args) : fn);
const TabsList = React.forwardRef((props, ref) => {
  const { className, ...other } = props;
  return (
    <BaseTabsList
      ref={ref}
      className={clsx(
        'flex font-sans p-3 items-center justify-center content-between min-w-tabs-list shadow-lg',
        className,
      )}
      {...other}
    />
  );
});
TabsList.propTypes = {
  className: PropTypes.string,
};
const Tab = React.forwardRef((props, ref) => {
  return (
    <BaseTab
      ref={ref}
      {...props}
      slotProps={{
        ...props.slotProps,
        root: (ownerState) => {
          const resolvedSlotProps = resolveSlotProps(
            props.slotProps?.root,
            ownerState,
          );
          return {
            ...resolvedSlotProps,
            className: clsx(
              `font-sans ${
                ownerState.selected
                  ? 'text-white bg-regal-blue'
                  : 'text-regal-blue bg-white  hover:bg-regal-blue'
              } ${
                ownerState.disabled
                  ? 'cursor-not-allowed opacity-50'
                  : 'cursor-pointer'
              } text-lg leading-[1.3] font-semibold w-full py-2.5 px-3 m-1.5 border-0 rounded-md flex justify-center focus:outline-0 focus:shadow-outline-purple-light`,
              resolvedSlotProps?.className,
            ),
          };
        },
      }}
    />
  );
});
Tab.propTypes = {
  /**
   * The props used for each slot inside the Tab.
   * @default {}
   */
  slotProps: PropTypes.shape({
    root: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  }),
};
const TabPanel = React.forwardRef((props, ref) => {
  const { className, ...other } = props;
  return (
    <BaseTabPanel
      ref={ref}
      className={clsx(
        'px-3 bg-white mb-4 w-full font-sans text-sm',
        className,
      )}
      {...other}
    />
  );
});
TabPanel.propTypes = {
  className: PropTypes.string,
};
/// drop down -----------------------------------------

