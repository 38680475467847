import React, { useState } from 'react';
import imps from './assets/imps.svg';
import upi from './assets/upi.svg';
import rupay from './assets/rupay.png';



export default function INR({ onPaymentMethodChange }) {
    const [selectedMethod, setSelectedMethod] = useState(null);

    const handlePaymentMethodSelection = (method) => {
        setSelectedMethod(method);

        // Call the function passed from the parent component to update the selected payment method
        onPaymentMethodChange(method);
    };
        return (
            <>
            <div class="flex flex items-center">
                <div
                    id="imps"
                    className={
                        selectedMethod === 'Imps'
                            ? 'border-1 border-blue-500 flex items-center p-2 rounded-md transition-all'
                            : 'border flex items-center p-2 rounded-md transition-all'
                    }
                    onClick={() => handlePaymentMethodSelection('Imps')}
                >
                    <input
                        id="default-radio-Imps"
                        type="radio"
                        value=""
                        name="default-radio-2"
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label htmlFor="default-radio-Imps" class="flex items-center">
                        <img src={imps} class="w-16 pl-2" />
                        {selectedMethod === 'Imps' && (
                            <span class="pl-2 text-xs text-blue-900">IMPS</span>
                        )}
                    </label>
                </div>
            </div>
            <div class="flex flex items-center">
                <div
                    id="upi"
                    className={
                        selectedMethod === 'Upi'
                            ? 'border-1 border-blue-500 flex items-center p-2 rounded-md transition-all'
                            : 'border flex items-center p-2 rounded-md transition-all'
                    }
                    onClick={() => handlePaymentMethodSelection('Upi')}
                >
                    <input
                        id="default-radio-Upi"
                        type="radio"
                        value=""
                        name="default-radio-2"
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label htmlFor="default-radio-Upi" class="flex items-center">
                        <img src={upi} class="w-14 pl-2" />
                        {selectedMethod === 'Upi' && (
                            <span class="pl-2 text-xs text-blue-900">UPI transfer</span>
                        )}
                    </label>
                </div>
            </div>
            </>
        );
    }

 